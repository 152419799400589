<template>
    <div class="tti-module">
        <div  
            v-if="config.content.length != 0" 
            class="tti-module-content"
        >
            <tti-sub 
                v-for="(profile, i) in config.content"
                :id="'team-sub-'+i"
                :key="i" 
                :i="i" 
                :config="profile"
            />
        </div>
        <h3 v-else>Please double click me...</h3>
    </div>
</template>

<script>

    import TTISubmod from "./sub-modules/TTI-Submod.vue";

    export default {
        props: ["config"],
        components: {
            TtiSub: TTISubmod
        }
    }
      
</script>