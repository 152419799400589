<template>
    <div 
        class="tti-submod-content" 
        @dblclick="lazyProfileFetch()"
    >
        <div class="tti-image-container">
            <div 
                class="tti-image-content" 
                :style="'background-image: url('+ getImg(config.image) +')'"
            />
        </div>
        <div class="tti-text-content">
            <inline-edit element="h2" :target="config.title"/>
            <inline-edit element="p" :target="config.text"/>
        </div>
        <div v-if="config.button">
            <inline-edit :link="config.button.link" element="a" :target="config.button.text"/>
        </div>
    </div>
</template>

<script>

    import * as imgManager from "@/store/imgManager";

    export default {
        props: ["config", "i"],
        methods: {
            lazyProfileFetch(){
                if(this.$store)
                this.$store.state.selectedProfile = this.i;
            },
            getImg(image){
                let img = imgManager.getImage(image);
                if(img){
                    if(img.path){
                        return img.path;
                    }else{
                        return img.file;
                    }
                }
            }
        },
        mounted(){
            if(!this.config.image){
                this.config.image = ""
            }
            if(!this.config.title){
                this.config["title"] = ""
            }
            if(typeof this.config.title === 'string'){
                this.config.title= {value: this.config.title}
            }
            if(!this.config.text){
                this.config["text"] = ""
            }
            if(typeof this.config.text === 'string'){
                this.config.text= {value: this.config.text}
            }
            if(typeof this.config.button.text === 'string'){
                this.config.button["text"] = {value: this.config.button.text}
            }
        }
    }
      
</script>